@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:ital,wght@0,400;1,600&display=swap');

.App{
  text-align: center;
  background-color: #fff;
  /* background-image: url("../public/white.jpg");
  background-size: cover;
  background-position: center; */
  min-height: 100vh;
  position: relative;
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.btn-danger{
  min-width: fit-content;
}

/* ----------------------Header------------------------- */

.navbar-toggler:focus{
  outline: none!important;
  box-shadow: none!important;
}

.header-navbar{
  background-color: #096b7ee9;
  opacity: 85%;
  padding: 20px;
  position: absolute;
  z-index: 1000;
  width: 100%;
}

.header-navbar a{
  color: #FFF;
  margin-bottom: 5px;
}

.header-navbar a:hover{
  background-color: #386faa;
  color: #FFF;
}

.expanded {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
}

.logo{
  color: #ffffff;
  margin-left: 5px;
}

.navbar-toggler{
  background-color: #FFF!important;
}

.navbar .show{
  opacity: 100%!important;
}

.navbar-toggler-icon{
  background-color: #FFF!important;
}

.menu{
  color: #FFF;
}

.menu:hover{
  cursor: pointer;
  /* color: #22805B; */
}

.little-logos{
  width: 25px;
  margin-right: 10px;
}

/* ----------------------Header------------------------- */

/* ----------------------Main------------------------- */



.main-bg{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.texts-grey{
  color: rgb(63, 62, 62);
}

.video-bg{
  position: relative;
}

video{
  object-fit: cover!important;
}

.video-container {
  width: 100%;
  height: 750px;
  overflow: hidden;
}

.cards-container{
  /* background-color: rgb(251, 253, 255); */
  height: 650px;
  align-items: center;
  border-radius: 10px;
  width: 95%;
  position: absolute;
  top: 110px;
  flex-direction: column;
}

a{
  list-style: none!important;
}

.cards{
  color: #FFF;
  height: 200px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform .2s;
  width: 100%;
  /* box-shadow: 0px 0px 7px 2px rgba(39, 146, 119, 0.75); */
}

.wp-card{
  /* background-image: url("../public/wp_bg.jpg");
  background-size: cover;
  background-position: center; */
  /* background-color: #14ba32; */
  background-image: linear-gradient(rgba(132, 201, 134, 0.84), rgba(27, 145, 14, 0.84) 50%);
  box-shadow: rgba(31, 91, 32, 0.24) 0 2px 2px,rgba(132, 201, 145, 0.4) 0 8px 12px;
  opacity: 100%;
}

.tg-card{
  /* background-image: url("../public/tg_bg.jpg");
  background-size: cover;
  background-position: center; */
  /* background-color: rgb(13, 84, 164); */
  background-image: linear-gradient(rgba(132, 173, 201, 0.84), rgba(14, 101, 145, 0.84) 50%);
  box-shadow: rgba(31, 72, 91, 0.24) 0 2px 2px,rgba(132, 175, 201, 0.4) 0 8px 12px;
  opacity: 100%;
}

.cards:hover{
  cursor: pointer;
}

.cards a{
  color: #FFF;
}

/* .links-bg{
  background-color: #fdfdfd;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  opacity: 80%;
  padding: 10px;
} */

.bottom-bg{
  background-image: url("../public/poker.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  justify-content: center;
}

@media (min-width: 576px) {
  .bottom-bg {
    height: 400px;
  }
}

.bottom-video{
  box-shadow: 0px 0px 30px 20px rgba(14, 14, 14, 0.75);
}

/* ----------------------Main------------------------- */

/* ----------------------Footer------------------------- */

.footer-container{
  background-color: #096b7ee9;
  min-height: 100px;
  position: static;
  bottom: 0;
  opacity: 85%;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ----------------------Footer------------------------- */

.button-1 {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(rgba(181, 141, 199, 0.84), rgba(82, 58, 58, 0.84) 50%);
  border-radius: 21px;
  border-width: 0;
  box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px,rgba(179, 132, 201, 0.4) 0 8px 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  font-family: Quicksand,sans-serif;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: .04em;
  line-height: 16px;
  margin: 0;
  padding: 18px 18px;
  text-align: center;
  text-decoration: none;
  text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px,rgba(255, 255, 255, 0.2) 0 0 12px,rgba(57, 31, 91, 0.6) 1px 1px 4px,rgba(57, 31, 91, 0.32) 4px 4px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
}

.button-1:hover {
  background-image: linear-gradient(#B384C9, #391F5B 50%);
}

@media (min-width: 768px) {
  .button-1 {
    font-size: 21px;
    padding: 18px 34px;
  }
}

.button-2 {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(rgba(200, 158, 128, 0.84), rgba(106, 111, 40, 0.84) 50%);
  border-radius: 21px;
  border-width: 0;
  box-shadow: rgba(91, 59, 31, 0.24) 0 2px 2px,rgba(201, 169, 132, 0.4) 0 8px 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  font-family: Quicksand,sans-serif;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: .04em;
  line-height: 16px;
  margin: 0;
  padding: 18px 18px;
  text-align: center;
  text-decoration: none;
  text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px,rgba(255, 255, 255, 0.2) 0 0 12px,rgba(57, 31, 91, 0.6) 1px 1px 4px,rgba(57, 31, 91, 0.32) 4px 4px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
}

.button-2:hover {
  background-image: linear-gradient(#c9ab84, #5b3f1f 50%);
}

@media (min-width: 768px) {
  .button-2 {
    font-size: 21px;
    padding: 18px 34px;
  }
}

.list-style{
  list-style: none!important;
}